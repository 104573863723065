import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import logo from "../raygun.svg";
import { supabase } from "../utils/supabaseClient";
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Cart from './Cart';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PROMISE);


function Header({ session, setSession, cartVisible}) {
  const navigate = useNavigate();
  const [isCartVisible, setIsCartVisible] = useState(cartVisible);
console.log("cart visible ", cartVisible);
  //search bar logic

  const [searchInput, setSearchInput] = useState('');
  // Handle change in search input
  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  // Function to execute search
  const executeSearch = () => {
    fetch(`https://server.voteronaldraygun.com:8443/search?q=${encodeURIComponent(searchInput)}`)
    .then(response => response.json())
    .then(data => {
        console.log('Search results:', data);
        // Update the state or context with these results to display them in your UI
    })
    .catch(error => {
        console.error('Error fetching search results:', error);
    });
    console.log('Searching for:', searchInput);
  };

  // Handle search on Enter key press
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      executeSearch();
    }
  };

  

  useEffect(() => {
    const cartItems = JSON.parse(localStorage.getItem('cart')) || [];
    if (cartItems.length > 0) {
      setIsCartVisible(true);
    }
  }, []);

  useEffect(() => {
    setIsCartVisible(cartVisible);
  }, [cartVisible]);
  

  async function handleSignOut() {
    const { error } = await supabase.auth.signOut();
    if (!error) {
      setSession(null);
      navigate("/");
    }
  }

  const toggleCart = () => {
    setIsCartVisible(prev => !prev);
  };
  
  

  const handleViewCart = () => {
    navigate("/checkout");

  };

  const handleCheckout = () => {
    // Retrieve cart items from state or context
    const cartItems = JSON.parse(localStorage.getItem('cart')) || [];
    fetch("https://server.voteronaldraygun.com:8443/create-checkout-session", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ cartItems }), // Send cart items to server
    })
    .then(response => response.json())
    .then(data => {
      console.log(data);
      if (data.id) {
        stripePromise.then(stripe => {
            stripe.redirectToCheckout({ sessionId: data.id });
        });
    } else {
        throw new Error('Session ID not received');
    }
    })
    .catch(error => {
      console.error('Error:', error);
    });
  };
  const getTotalItems = () => {
    const cartItems = JSON.parse(localStorage.getItem('cart')) || [];
    return cartItems.reduce((total, item) => total + item.quantity, 0);
  };
  

  return (
    <div className="header">
      <div className="nav">
        <div className="logo">
          <a href="/">
            <img src={logo} alt="Logo" height={40} />
          </a>
        </div>
      </div>
      <div className="search">
      <input 
          type="text" 
          placeholder="Search for products and brands..." 
          value={searchInput}
          onChange={handleSearchInputChange}
          onKeyPress={handleKeyPress}
        />
      </div>
      <div className="right-section" style={{ display: "flex", alignItems: "center" }}>
  {session ? (
    <>
      <div className="cart" style={{ marginRight: "25px", position: 'relative' }} onClick={toggleCart}>
        <FontAwesomeIcon icon={faShoppingCart} size='sm' style={{ color: 'black', cursor: 'pointer' }} />
        {getTotalItems() > 0 && (
          <span className="cart-badge">{getTotalItems()}</span>  // Only show badge if items > 0
        )}
        {isCartVisible && (
          <div className="cart-popout">
            <h3>Your Cart</h3>
           <Cart/>
           {getTotalItems() > 0 && (
            <div className="cart-actions">
              <button onClick={handleViewCart}>View Cart</button>
              <button onClick={handleCheckout}>Checkout</button>
            </div>
    )}
          </div>
        )}
      </div>
      <button className="sign-out-btn" onClick={handleSignOut}>Logout</button>
    </>
        ) : (
          <>
            <a href="/login" className="login-link">Login</a>
            <a href="/join" className="join-us-link">Join Us</a>
          </>
        )}
      </div>
    </div>
  );
}

export default Header;
