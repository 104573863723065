import React from 'react';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import BrandCard from '../components/BrandCard';

function Brands({ session, setSession }) {
  return (
    <div>
      <Header session={session} setSession={setSession} cartVisible={false}/>
      <div className="container">
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="main-content">
          <BrandCard />
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Brands;
