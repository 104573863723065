import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../raygun.svg';

export default function Footer() {
  const style = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
    background: '#ffffff',
    height: '70px',
    fontSize: '12px',
    fontWeight: 'bold',
    color: 'gray',
  };

  const linkStyle = {
    textDecoration: 'none',
    color: 'gray',
    margin: '0 10px', // Add some margin to separate the links
  };

  const logoStyle = {
    height: '30px',
    width: 'auto',
  };

  const innerDivStyle = {
    display: 'flex',
    alignItems: 'center',
  };

  return (
    <div style={style}>
      <img src={logo} alt="Raygun logo" style={logoStyle} />
      <div style={innerDivStyle}>
        <Link to="/help" style={linkStyle}>Help</Link>
        <p style={linkStyle}>(305)790-9458</p> {/* Replace with your phone number */}
        <Link to="/terms-and-conditions" style={linkStyle}>Terms and Conditions</Link>
      </div>
      <p>© 2024 Ronald Raygun LLC. All rights reserved.</p>
    </div>
  );
}