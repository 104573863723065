import React from 'react'
import { FaHome, FaStar, FaTags, FaFire, FaHistory, FaList } from 'react-icons/fa'
import '../Sidebar.css'

function Sidebar({session}) {
  return (
    <div className="sidebar" style={{ backgroundColor: '#f5f5f5', minWidth: '200px', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '10px'}}>
      <button className="button" onClick={() => window.location.href='/'}>
        <FaHome style={{ marginRight: '10px'}} />Home
      </button>      
      <button className="button" onClick={() => window.location.href='/brands'}><FaTags style={{ marginRight: '10px' }} />Brands</button>
      

      <button className="button" onClick={() => window.location.href='/categories'}>
         <FaList style={{ marginRight: '10px' }} />Categories
       </button>
      <button className="button" onClick={() => window.location.href='/limited'} style={{ color: '#D4AF37' }}>
  <FaStar style={{ marginRight: '10px' }} />Limited Edition
</button>
      <button className="button" onClick={() => window.location.href='/brands'}><FaFire style={{ marginRight: '10px' }} />Trending</button>

      {/* <button className="button"  onClick={() => window.location.href='/categories'}><FaPercent style={{ marginRight: '10px' }}  />Deals</button> */}
      <button className="button" onClick={() => window.location.href = '/orders'}>
        <FaHistory style={{ marginRight: '10px' }} />Order History
      </button>
    </div>
  )
}

export default Sidebar