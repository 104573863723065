import React from 'react';

function Cart() {


const removeFromCart = (productId) => {
    let cartItems = JSON.parse(localStorage.getItem('cart')) || [];
    cartItems = cartItems.filter(item => item['SKU'] !== productId);
    localStorage.setItem('cart', JSON.stringify(cartItems));  
    window.location.reload();
  };

    const getCartItems = () => {

        const cartItems = JSON.parse(localStorage.getItem('cart')) || [];
        console.log("Cart Items: ", cartItems); // Add this line to log cart items
      
          if (cartItems.length === 0) {
            console.log("Cart is empty"); // Add this line to confirm the empty cart
      
            return <div>...is empty 😬</div>;
          } 
          return (
            <>
              {cartItems.map((item, index) => (
                <div key={index} className="cart-item">
                  <div>Name: {item.NAME}</div>
                  <div>Quantity: {item.quantity}</div>
                  <div>Price: ${item.PRICE}</div>
                  <button onClick={() => removeFromCart(item['SKU'])} className="remove-item-btn">X</button>
      
                  {/* Add more fields as needed based on your product structure */}
                </div>
              ))}
            </>
          );
        };

  return <div>{getCartItems()}</div>;
}

export default Cart;
