import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Header from '../components/Header';

function Join({ session }) {
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const widgetScriptSrc = 'https://tally.so/widgets/embed.js';

        const load = () => {
            // Existing load logic
            document
                .querySelectorAll('iframe[data-tally-src]:not([src])')
                .forEach((iframeEl) => {
                    iframeEl.src = iframeEl.dataset.tallySrc;
                });
        };

        if (document.querySelector(`script[src="${widgetScriptSrc}"]`) === null) {
            const script = document.createElement('script');
            script.src = widgetScriptSrc;
            script.onload = load;
            script.onerror = load;
            document.body.appendChild(script);
        }

        const iframeTimeout = setTimeout(() => {
            if (isLoading) {
                setIsLoading(false);
            }
        }, 1000); 
        return () => clearTimeout(iframeTimeout);

    }, [isLoading]);

      // Inline styles for the spinner and container
      const spinnerStyle = {
        border: '5px solid #f3f3f3', // Light grey
        borderTop: '5px solid #3498db', // Blue
        borderRadius: '50%',
        width: '50px',
        height: '50px',
        animation: 'spin 2s linear infinite'
    };

    const spinnerContainerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '80vh', // Full viewport height
        width: '100%'
    };

    if (session) {
        navigate('/');
        return null;
    }

    const handleIframeLoad = () => {
        setIsLoading(false);

    };

      // Keyframes for the spinner
      const keyframes = `
      @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
      }
  `;

    return (
        <div>
        <Header session={session} />
        {isLoading && (
            <div style={spinnerContainerStyle}>
                <div style={spinnerStyle}></div>
            </div>
        )}
        <iframe
            style={{ display: isLoading ? 'none' : 'block', width: '100%', height: '800px' }} // Adjust as needed
            data-tally-src="https://tally.so/r/nrPBkv"
            onLoad={handleIframeLoad}
            loading="lazy"
            frameBorder={0}
            marginHeight={0}
            marginWidth={0}
            title="Newsletter subscribers"
        ></iframe>
        <style>{keyframes}</style> {/* Inline keyframes */}
    </div>
    );
}

export default Join;
