import React from 'react'
import Sidebar from '../components/Sidebar'
import Header from '../components/Header'
import Footer from '../components/Footer'
import CategoriesCard from '../components/CategoriesCard'

function Categories({ session, setSession }) {
  return (
    <div>
    <Header session={session} setSession={setSession}/>
    <div className="container">
          <div className="sidebar">
            <Sidebar />
          </div>
      <div className="main-content">
        <CategoriesCard />
      </div>
    </div>
    <Footer />
  </div>
  )
}

export default Categories