import React, { useState} from 'react';
import '../ProductCard.css';
import { useNavigate } from "react-router-dom";



function ProductCard({ product, addToCart, stockQuantity, cart, session}) {
  const [quantity, setQuantity] = useState(1);
  const [showPopup, setShowPopup] = useState(false);  
  const navigate = useNavigate();

  const handleAddToCart = () => {
    if (!session) {
      navigate('/login');
      return;
    }
    const totalQuantityInCart = cart.reduce((total, item) => {
      return item['SKU'] === product['SKU'] ? total + item.quantity : total;
    }, 0);
    const totalQuantity = totalQuantityInCart + parseInt(quantity, 10);

    if (totalQuantity > stockQuantity) {
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 2200);
    } else {
      addToCart(product, parseInt(quantity, 10));
      setShowPopup(false);
      window.location.reload();
    }
  };
  return (
    <div className="product-card">
      <h4 className="product-name">{product.NAME}</h4>
      <img src={product.IMAGE || 'https://via.placeholder.com/150'} alt={product.Name} />
      <p className="product-price">${product.PRICE}</p>
      <div className="add-to-cart-container"> {/* Added container */}
        <input 
          type="number" 
          value={quantity} 
          onChange={(e) => setQuantity(e.target.value)} 
          min="1" 
          className="quantity-input"
        />
        <button onClick={handleAddToCart} className="add-to-cart-button">Add to Cart</button>
        {showPopup && <div className="out-of-stock-popup">Not enough in stock</div>}
      </div>
    </div>
  );
}

export default ProductCard;
