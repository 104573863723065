import React from 'react';
import '../layouts/GuestBanner.css';
import { useNavigate } from 'react-router-dom';
import yourImage from '../RR_RonaldSunglasses.png'; // Import your image here

function GuestBanner() {

  const navigate = useNavigate();

  function handleNav() {
    navigate('/join');
  }

  const bannerStyle = {
    display: 'flex',
    flexDirection: 'row', // Changed from 'column' to 'row'
    justifyContent: 'space-between', // Adjust as needed to space elements
    alignItems: 'center', // Adjust vertical alignment as needed
    width: '90%',
    height: '50vh',
    maxHeight: '350px',
    overflow: 'auto',
    borderRadius: '15px',
    background: 'linear-gradient(to right, #000000 40%, #274db7 100%)',
    color: 'white',
    margin: '20px',
    padding: '20px',
    fontSize: '24px',
    fontWeight: 'bolder',
  };

  

  return (
    <div style={bannerStyle}>
      <div>
        <h2 style={{marginBottom: '10px'}}>Discover products that fly off the shelves.</h2>
        <h5>Premium rolling paper and other accessories.</h5>
        <button onClick={handleNav} className="join-us-button">Join Now</button>
      </div>
      <img className="image" src={yourImage} alt="Your alt text" />
    </div>
  );
}

export default GuestBanner;
