import React from 'react'

function SectionHeader({title , backto}) {
  return (
    <div style={{ display: 'block', marginTop:'15px' }}>    
      <span style={{ fontWeight: 'bold', color: 'black', cursor: 'pointer'}} onClick={() => window.location.href=`/${backto}`}>
        ← Back to {backto}
      </span>
      <h1>{title}</h1>
    </div>
  )
}

export default SectionHeader