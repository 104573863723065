import React from 'react';
import { FaStar } from 'react-icons/fa'
import { Link } from 'react-router-dom';


function LimitedEditionBanner() {
  const style = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center', // Align items to the center horizontally
    width: '90%',
    height: '20vh',
    maxHeight: '350px',
    overflow: 'auto',
    borderRadius: '15px',
    background: '#f0f0f0',
    margin: '20px',
    padding: '20px 10px 10px 10px',
  };

  return (
    <div style={style}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <FaStar style={{ color: '#D4AF37', marginRight: '10px' }} />
        <h3 style={{ color: '#D4AF37', margin: '0', padding: '0' }}>Limited Edition</h3>
        <FaStar style={{ color: '#D4AF37', marginLeft: '10px' }} />
      </div>
      <p style={{ color: '#D4AF37', textAlign: 'center', marginTop: '20px', fontSize: '18px', font:""}}>
       Must be a Gold Member to see limited edition items
      </p>
      <Link to="/limited" className="see-more">MORE INFO</Link>
      {/* Rest of the content */}
    </div>
  );
}

export default LimitedEditionBanner;