import React from 'react';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ProductLoader from '../components/ProductLoader';

function CategoryPage({ categoryData ,session, setSession }) {

  return (
     <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
     <Header session={session} setSession={setSession} cartVisible={true}/>
     <div style={{ display: 'flex', position: 'relative' }}>
       <Sidebar style={{ width: '20%', position: 'fixed', overflow: 'auto', height:'100vh' }} />
       <ProductLoader session={session} style={{ width: '100%', marginLeft: '20%', overflow: 'auto' }} title = {categoryData.Name}/>
     </div>
     <Footer />
   </div>
  );
}

export default CategoryPage;