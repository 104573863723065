import Checkout from "./pages/Checkout";
import Login from "./pages/Login";
import Orders from "./pages/Orders";
import Body from "./components/Body";
import Join from "./pages/Join";
import Brands from "./pages/Brands";
import { BrowserRouter as Router, Route, Routes, useLocation, useParams } from "react-router-dom";
import { useState, useEffect } from 'react'
import { supabase } from './utils/supabaseClient'
import BrandPage from "./pages/BrandPage";
import Categories from "./pages/Categories";
import CategoryPage from "./pages/CategoryPage";
import LimitedEdition from "./pages/LimitedEdition";
import Success from "./pages/Success";



function App() {
  const [session, setSession] = useState(null);
  

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      console.log("session state ", session);      

    });

    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  function RedirectToReeferMadness() {
    useEffect(() => {
      window.location.href = 'https://reefermadness.shop';
    }, []);
  
    return null; // Render nothing or a loading indicator while redirecting
  }
  
  function BrandPageRoute() {
    const { brandName } = useParams();
    const location = useLocation();
    const brandData = location.state?.brandData; // Get brand data passed during navigation
    return <BrandPage session={session} setSession ={setSession} brandName={brandName} brandData={brandData} />;
  }

  function CategoryRoute() {
    const { categoryName } = useParams();
    const location = useLocation();
    const categoryData = location.state?.categoryData; // Get category data passed during navigation
    console.log("brand data ", categoryData);

  
    return <CategoryPage session={session} setSession ={setSession} categoryName={categoryName} categoryData={categoryData} />;
  }

 
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login session={session} setSession={setSession} />} />
        <Route path="/" element={<Body session={session}setSession={setSession} />} />
        <Route path="/orders" element={<Orders session={session} setSession={setSession} />} />
        <Route path="/checkout" element={<Checkout session={session} setSession={setSession} />} />
        <Route path="/join" element={<Join session={session} />} />
        <Route path="/brands" element={<Brands session={session} setSession ={setSession} />} />
        <Route path="/brand/:brandName" element={<BrandPageRoute />} />
        <Route path="/categories" element={<Categories session={session} setSession ={setSession}/>} />
        <Route path="/category/:categoryName" element={<CategoryRoute  session={session} setSession ={setSession}  />} />
        <Route path="/limited" element={<LimitedEdition session={session}/>} />
        <Route path="/success" element={<Success session={session} setSession={setSession}/>} />
        <Route path="/rm" element={<RedirectToReeferMadness />} />
      </Routes>
    </Router>
  );
}

export default App;
