import React, { useState, useEffect } from 'react';
import '../Main.css';
import ProductCard from '../components/ProductCard';
import SectionHeader from '../components/SectionHeader';

function ProductLoader({ session , title}) {
  const [products, setProducts] = useState([]);
  const [filter, setFilter] = useState('All');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [cart, setCart] = useState(() => {
    const storedCart = localStorage.getItem('cart');
    return storedCart ? JSON.parse(storedCart) : [];
  });
  useEffect(() => {
    const cacheKey = `products-${title}`;
    const cachedData = sessionStorage.getItem(cacheKey);
  
    if (cachedData) {
      setProducts(JSON.parse(cachedData));
      setLoading(false);
    } else {
      fetch(`https://server.voteronaldraygun.com:8443/categories/${title}`)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          setProducts(data.data);
          sessionStorage.setItem(cacheKey, JSON.stringify(data.data));
          setLoading(false);
        })
        .catch(error => {
          console.error('There was a problem with the fetch operation:', error);
          setError(error.message);
          setLoading(false);
        });
    }
  }, [title]);
  
  
  

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  const addToCart = (product, selectedQuantity) => {
    setCart(currentCart => {
      const existingProductIndex = currentCart.findIndex(item => item['SKU'] === product['SKU']);
      if (existingProductIndex !== -1) {
        const updatedCart = [...currentCart];
        updatedCart[existingProductIndex] = {
          ...updatedCart[existingProductIndex],
          quantity: updatedCart[existingProductIndex].quantity + selectedQuantity
        };
        return updatedCart;
      } else {
        const newProduct = { ...product, quantity: selectedQuantity };
        return [...currentCart, newProduct];
      }
    });
  };

  const filteredProducts = filter === 'All' ? products : products.filter(product => product.TYPE === filter);

  return (
    <div className="main-container">
      <SectionHeader title={title} backto={'Categories'} />
      <div className="filters-section">
        <button onClick={() => setFilter('All')}>All</button>
        <button onClick={() => setFilter('Papers')}>Papers</button>
        <button onClick={() => setFilter('Trays')}>Trays</button>
        <button onClick={() => setFilter('Grinders')}>Grinders</button>
        <button onClick={() => setFilter('Merch')}>Merch</button>
      </div>
      <div className="products-section">
        {loading && <div>Loading...</div>}
        {error && <div className='error-message'>There has been an issue getting product information. Error: {error}</div>}
        {!loading && !error && filteredProducts && filteredProducts.map((product, index) => (

          <ProductCard 
            key={index}
            product={product}
            addToCart={addToCart}
            stockQuantity={product.INVENTORY}
            cart={cart} // Pass the cart state to ProductCard
            session={session}
          />
        ))}
      </div>
    </div>
  );
}

export default ProductLoader;
