import React from 'react'
import Header from './Header'
import Footer from './Footer'
import Sidebar from './Sidebar'
import GuestBanner from '../layouts/GuestBanner'
import ExploreBrands from '../layouts/ExploreBrands';
import ExploreCategories from '../layouts/ExploreCategories';
import '../layouts/HomePageBanners.css'
import LimitedEditionBanner from '../layouts/LimitedEditionBanner';
import Testimonials from '../layouts/Testimonials';


function Body({ session, setSession}) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Header session={session} setSession={setSession}/>
      <div style={{ display: 'flex' }}>
        <div className="sidebar">
          <Sidebar session={session}/>
        </div>
        <div style={{ width: '80%', flex: 'none', overflow: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', padding: '0 20px' }}>
          <GuestBanner />
          <div className="explore-container">
            <ExploreBrands />
            <ExploreCategories />
          </div>
          <LimitedEditionBanner className="limited-edition" />
          <Testimonials />

            
        </div> 
      </div>
      <Footer />
    </div>
  )
}

export default Body

