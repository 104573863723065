import React, { useState } from 'react';
import logo from '../raygun.svg';
import { useNavigate } from 'react-router-dom';
import '../Login.css'; // Ensure this is the correct path to your CSS file
import { supabase } from '../utils/supabaseClient';
import backgroundImage from '../loginwindow.png'; // Import the background image

const Login = ({session}) => {
    
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false); // State for the Remember Me checkbox
    const navigate = useNavigate();
    const [loginError, setLoginError] = useState(''); // State for login error messages


    if (session) {
        navigate('/');
        return;
      }

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoginError('');

        // Attempt to sign in with email and password
        const {user, error, session } = await supabase.auth.signInWithPassword({ email, password });
    
        if (error) {
            console.error('Error logging in:', error);
            setLoginError('Incorrect username or password.'); // Set the login error message
        } else {
            console.log('User logged in', user);
            console.log('Session:', session);
            navigate('/');
        }
    };
    
    const handleJoinUs = () => {
        navigate('/join');
      };

    const handleReturnHome = () => {
        navigate('/'); // Update this to your home route
    };

    const handleForgotPassword = () => {
        // Implement forgot password logic or navigation here
        console.log('Forgot Password Clicked');
    };

    return (
        <div className="login-page" style={{ minHeight: '100vh', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', backgroundColor: '#f0f0f0' }}>
            <div className="login-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <h2>Log Into Your Business Account</h2>
                <a href='./'>
                    <img src={logo} alt="Logo" style={{ width: '250px', height: 'auto', marginBottom: '40px' }} />
                </a>
                <form onSubmit={handleLogin} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" style={{ padding: '15px', width: '250px', marginBottom: '20px', borderRadius: '5px', border: '1px solid #ddd' }} />
                    <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" style={{ padding: '15px', width: '250px', marginBottom: '10px', borderRadius: '5px', border: '1px solid #ddd' }} />
    
                    {/* Display login error message */}
                    {loginError && (
                        <div style={{ color: 'red', marginBottom: '20px' }}>
                            {loginError}
                        </div>
                    )}
    
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '250px', marginBottom: '20px' }}>
                        <label>
                            <input type="checkbox" checked={rememberMe} onChange={() => setRememberMe(!rememberMe)} />
                            Remember Me
                        </label>
                        <button type="button" onClick={handleForgotPassword} style={{ backgroundColor: 'transparent', color: '#007bff', fontWeight: 'bold', border: 'none', padding: 0, cursor:'pointer' }}>
                            Forgot Password?
                        </button>
                    </div>
                    <button type="submit" style={{ padding: '10px 20px', borderRadius: '5px', border: 'none', backgroundColor: '#007bff', color: 'white', fontWeight: 'bold', width: '250px', cursor: 'pointer' }}>
                        Login
                    </button>
                    <p>Not a member? <button onClick={handleJoinUs} type="button" style={{ backgroundColor: 'transparent', color: '#007bff', fontWeight: 'bold', border: 'none', cursor: 'pointer'}}>Join Raygun</button></p>
                </form>
                <button onClick={handleReturnHome} style={{ 
                    marginTop: '20px', 
                    backgroundColor: 'transparent', 
                    color: '#007bff', 
                    fontWeight: 'bold', 
                    border: 'none', 
                    fontSize: '1em',
                    cursor: 'pointer' 
                }}>Return to Home Page</button>
            </div>
            <div className="image-container">
                <img src={backgroundImage} alt="Login Window" style={{ width: '65%', height: 'auto', borderRadius: '4%' }} />
            </div>
        </div>
    );
    
};

export default Login;
