import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function CategoriesCard() {
    const [category, setCategory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const cacheKey = 'categories';
        const cachedData = sessionStorage.getItem(cacheKey);

        if (cachedData) {
            setCategory(JSON.parse(cachedData));
            setLoading(false);
        } else {
            fetch('https://server.voteronaldraygun.com:8443/categories')
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    setCategory(data);
                    sessionStorage.setItem(cacheKey, JSON.stringify(data));
                    setLoading(false);
                })
                .catch(error => {
                    console.error('There was a problem with the fetch operation:', error);
                    setError(error.message);
                    setLoading(false);
                });
        }
    }, []);

    const cardStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '10px',
        borderRadius: '10px',
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        width: '200px',
        height: '300px',
        overflow: 'hidden',
        padding: '10px',
        cursor: 'pointer'  // Change the cursor to indicate the card is interactive
    };
    const hoverEffect = (event, isHovering) => {
        if (isHovering) {
            event.currentTarget.style.transform = 'scale(1.05)';
            event.currentTarget.style.boxShadow = '0 6px 12px 0 rgba(0,0,0,0.4)';
        } else {
            event.currentTarget.style.transform = '';
            event.currentTarget.style.boxShadow = '0 4px 8px 0 rgba(0,0,0,0.2)';

        }
    };


    const imageContainerStyle = {
        flex: '1',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden'
    };

    const imageStyle = {
        maxHeight: '100%',
        maxWidth: '100%',
        objectFit: 'contain'
    };

    const containerStyle = {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        paddingTop: '20px'
    };

    const headingStyle = {
        width: '100%', // Ensure the heading spans the full width
        textAlign: 'center', // Center the heading text
        margin: '20px 0', // Add some margin for spacing
        fontSize: '24px', // Adjust the font size as needed
        fontWeight: 'bold', // Make the text bold for emphasis
    };

    return (
        <div>
            <h2 style={headingStyle}>Select A Category</h2>
            <div style={containerStyle}>
                {loading ? (
                    <div>Loading...</div>
                ) : error ? (
                    <div>Error: {error}</div>
                ) : (
                    category.map((category) => (
                        <div 
                        key={category.Name} 
                        style={cardStyle}
                        onMouseEnter={(e) => hoverEffect(e, true)}
                        onMouseLeave={(e) => hoverEffect(e, false)}
                        onClick={() => navigate(`/category/${category.Name.replace(/\s/g, '')}`, { state: { categoryData: category } })}
                        >
                            <div style={imageContainerStyle}>
                                <img src={category.Image} alt={category.Name} style={imageStyle} />
                            </div>
                            <h2>{category.Name}</h2>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
}

export default CategoriesCard;
