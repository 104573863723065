import React, { useEffect, useState, useCallback } from 'react';
import Header from '../components/Header'
import { supabase } from '../utils/supabaseClient'; // Import the initialized Supabase client
import { useNavigate } from 'react-router-dom';


function Orders({session, setSession}) {

  const navigate = useNavigate();
  
  const [orders, setOrders] = useState([])
  
  const phoneNumber = "123-456-7890" // Replace with the user's phone number
  const address = "123 Main St" // Replace with the user's address
  const user = session?.user?.email; // This will hold the email of the logged-in user
  const [loading, setLoading] = useState(true); // Add this line

 
  const fetchOrders = useCallback(async () => {
    setLoading(true);

    try {
      const { data, error } = await supabase
        .from('orders')
        .select('*')
        .eq('user_uid', session.user.id);
  
      if (error) throw error;
      setOrders(data);
    } catch (error) {
      console.error('Error fetching orders:', error.message);
    } finally {
      setLoading(false);
    }
  }, [session]); // Add dependencies of fetchOrders here

  useEffect(() => {
    if (session) {
      fetchOrders();
    } else if (!loading) {
      navigate('/login');
    }
  }, [session, loading, fetchOrders, navigate]);
  
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 40); // Adjust the delay as needed
  
    return () => clearTimeout(timer); // Clean up the timer
  }, []);
  return (
    <div>
      <Header session={session} setSession={setSession} />
      <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0 20px' }}>
        <h2 style={{ color: 'black', fontSize: '2em', marginRight: '20px' }}>{user}</h2> {/* Display the user's name */}
        <div>
          <p>{phoneNumber}</p> {/* Display the user's phone number */}
          <p>{address}</p> {/* Display the user's address */}
        </div>
      </div>
      <table style={{ width: '100%', marginTop: '20px', borderCollapse: 'collapse' }}>
        <thead style={{ backgroundColor: '#f2f2f2' }}>
          <tr>
            <th>Order #</th>
            <th>Date</th>
            <th>Status</th>
            <th>Details</th>
          </tr>
        </thead>
        <tbody>
        {orders.map(order => (
  <tr key={order.order_id}>
    <td style={{ textAlign: 'center', padding: '5px 0' }}>{order.order_id}</td>
    <td style={{ textAlign: 'center', padding: '5px 0' }}>{new Date(order.created_at).toLocaleDateString()}</td>
    <td style={{ textAlign: 'center', padding: '5px 0' }}>{order.status}</td>
    <td style={{ textAlign: 'center', padding: '5px 0' }}>{'$'+ order.total_price}</td>
  </tr>
))}
        </tbody>
      </table>
    </div>
  )
}

export default Orders