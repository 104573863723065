import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Sidebar from '../components/Sidebar'


function LimitedEdition({ session, setSession}) {

  return (
    <div style={{ display: 'flex', flexDirection: 'column'}}>
      <Header session={session} setSession={setSession}/>
      <div style={{ display: 'flex' }}>
        <div className="sidebar">
          <Sidebar style={{ width: '20%', position: 'fixed', overflow: 'auto', height:'100vh' }} />
        </div>
        <div style={{ width: '100%', textAlign: 'center' }}>
  <h1>Your Account is not <span style={{ color: 'gold' }}>Gold</span> Status</h1>
  <p>Please contact us for more information.</p>
</div>
      </div>
      <Footer />
    </div>
  )
}

export default LimitedEdition

