import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Import your images
import image1 from './icons/blazysusanbw.png';
import image2 from './icons/calicrusherbw.png';
import image3 from './icons/mjbw.png';
import image4 from './icons/onGrokbw.png';

function ExploreBrands() {
  const brands = [
    { Image: image1, Name: 'Brand 1' },
    { Image: image2, Name: 'Brand 2' },
    { Image: image3, Name: 'Brand 3' },
    { Image: image4, Name: 'Brand 4' },
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <button style={{ background: 'none', border: 'none', color: '#000 !important', fontSize: '24px' }} className="arrow next">→</button>,
    prevArrow: <button style={{ background: 'none', border: 'none', color: '#000 !important', fontSize: '24px' }} className="arrow prev">←</button>,
  };

  return (
    <div className="explore-card">
      <div className="card-header">
        <h2>Shop Brands</h2>
        <Link to="/brands" className="see-more">VIEW ALL</Link>
      </div>
      <Slider {...settings}>
        {brands.map((brand, index) => (
          <div key={index} style={{
            width: '180px',
            height: '180px',
            margin: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <Link to="/brands">
              <img src={brand.Image} alt={brand.Name} style={{
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                objectFit: 'cover'
              }} />
            </Link>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default ExploreBrands;
