import React, { useState } from 'react';
import Header from '../components/Header';
import Cart from '../components/Cart';
import { loadStripe } from '@stripe/stripe-js';
import '../Checkout.css';
import { Elements } from '@stripe/react-stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PROMISE);

function Checkout({ session, setSession }) {
  const [clientSecret] = useState('');

  const handleCheckout = () => {
    // Retrieve cart items from state or context
    const cartItems = JSON.parse(localStorage.getItem('cart')) || [];
    fetch("https://server.voteronaldraygun.com:8443/create-checkout-session", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ cartItems }), // Send cart items to server
    })
    .then(response => response.json())
    .then(data => {
      console.log(data);
      if (data.id) {
        stripePromise.then(stripe => {
            stripe.redirectToCheckout({ sessionId: data.id });
        });
    } else {
        throw new Error('Session ID not received');
    }
    })
    .catch(error => {
      console.error('Error:', error);
    });
  };
  
  


  return (
    <div>
        <Header session={session} setSession={setSession} />
        <div className="checkout-container" style={{ padding: '20px', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
            <div style={{ flex: 1, marginRight: '20px' }}>
                <h3>Your Cart</h3>
                <Cart />
            </div>
            <div style={{ flex: 1, backgroundColor: '#f2f2f2', padding: '20px' }}>
                <h3>Checkout Details</h3>
                <div style={{ margin: '20px 0' }}>
                    {/* User details form or display */}
                </div>
                <div style={{ margin: '20px 0' }}>
                    {/* Address form or display */}
                </div>
                <div style={{ margin: '20px 0' }}>
                    <h3>Payment Options</h3>
                    {clientSecret && (
                      <Elements stripe={stripePromise} options={{ clientSecret }}>
                      </Elements>
                    )}
                </div>
                <button onClick={handleCheckout} style={{ backgroundColor: '#4CAF50', color: 'white', padding: '15px 30px', fontSize: '16px', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
                    Place Order
                </button>
            </div>
        </div>
    </div>
  );
}

export default Checkout;
