import React, { useEffect } from 'react';
import Header from '../components/Header';
import { useNavigate } from 'react-router-dom';


function Success({ session, setSession }) {

const navigate = useNavigate();

  // Function to clear cart items from localStorage
  const clearCart = () => {
    localStorage.removeItem('cart');
  };

  // useEffect to clear the cart when the component mounts
  useEffect(() => {
    clearCart();
  }, []);

  return (
    <div>
      <Header session={session} setSession={setSession} />
      <div style={{ 
  display: 'flex', 
  justifyContent: 'center', 
  alignItems: 'center', 
  height: '100vh', 
  flexDirection: 'column',
  transform: 'translateY(-20%)' 
}}>
  <h1>Thank you for your purchase!</h1>
  <p>You will receive an email confirmation shortly.</p>
  <p>Within 24 hours, details will be visible in <button style={{ background: 'none', border: 'none', color: 'blue', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => navigate('/orders')}>Order History</button></p></div>
    </div>
  );
}

export default Success;
